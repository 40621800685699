@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,800&display=swap");

body,
* {
  font-family: "Poppins", sans-serif !important;
}

:root {
  --brandColor: #3e79f7;
  --backgroundColor: #fafafb;
  --acentColor: #d8e6df7a;
}

.ant-table-thead > tr > th {
  font-size: 1rem !important;
  font-weight: bolder;
  text-transform: capitalize;
}

.dmenu .ant-menu-item span,
.ant-menu-submenu,
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 1rem !important;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  font-size: 0.9rem !important;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  display: none !important;
}

/* .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    display: none !important;
}

.ant-menu ul, .ant-menu ol {
    font-size: 1rem !important;
    padding-left: 5px !important;
}

.ant-menu {
  font-family: "Poppins", sans-serif !important;
} */

.ant-btn-primary {
  height: 34px !important;
  font-size: 1rem !important;
  border-radius: 20px !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px !important;
}

.ant-btn-sm {
  height: 30px !important;
  font-size: 0.7rem !important;
  border-radius: 20px !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px !important;
}

.ant-btn[disabled] {
  border-color: transparent !important;
}

.ant-btn-primary svg {
  font-size: 1rem !important;
  transform: translateY(-3px);
}

.ant-pagination-item-link > .anticon {
  transform: translateY(-2px) !important;
}

.ant-form-item-label > label {
  letter-spacing: 0.2px !important;
  color: #444444e8 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  font-size: 20px !important;
  background-color: #ffffff !important;
}

.ant-table h5 > svg {
  font-size: 1.15rem !important;
}

.ant-table-tbody > tr > td {
  color: #455560;
}

.ant-input-number {
  width: 100% !important;
  height: 40px !important;
  padding: 2px 1px !important;
  text-align: left !important;
  background-color: transparent !important;
  border: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem !important;
  outline: 0 !important;
  transition: all 0.3s linear !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 0 !important;
}

.ant-input {
  margin: 0 !important;
  font-variant: tabular-nums !important;
  list-style: none !important;
  font-feature-settings: "tnum" !important;
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
  min-width: 0 !important;
  padding: 8.5px 11px !important;
  color: #455560 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  background-color: #fff !important;
  border: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem !important;
  transition: all 0.3s !important;
}

.ant-picker {
  width: 100% !important;
  min-width: 0 !important;
  padding: 8.5px 11px !important;
  border: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem !important;
  transition: all 0.3s !important;
  line-height: 1.5 !important;
}

.ant-select-selector {
  width: 100% !important;
  height: 40px !important;
  padding: 8.5px 11px;
  position: relative !important;
  color: #455560 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  background-color: #fff !important;
  border: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  transform: translateY(3px) !important;
  letter-spacing: 0.5px !important;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0px !important;
  border: 0px !important;
  outline: 0px !important;
}

.ant-input-affix-wrapper {
  position: relative !important;
  width: 100% !important;
  min-width: 0px !important;
  padding: 8.5px 11px !important;
  color: rgb(69, 85, 96) !important;
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  border: 1px solid rgb(230, 235, 241) !important;
  border-radius: 0.625rem !important;
  display: inline-flex !important;
}

.ant-input[disabled],
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.45) !important;
  background-color: #f5f5f5 !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
}

.ant-input[disabled]:hover,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input-number-disabled:hover {
  border-color: transparent !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.45) !important;
  background-color: #f5f5f5 !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-picker:hover,
.ant-picker:focus .ant-select-selector:focus,
.ant-select-selector:hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: var(--brandColor) !important;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input-affix-wrapper:hover,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: #fff !important;
  border-color: #ff6b72 !important;
}

t-form-item-label > label {
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  height: 40px !important;
  font-size: 14px !important;
  color: #1a3353 !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px !important;
  line-height: 1.5715 !important;
  white-space: initial !important;
  text-align: left;
}

.ant-pagination-item,
.ant-pagination-total-text .ant-pagination-item {
  border-radius: 50% !important;
  border-color: transparent !important;
  line-height: 30px !important;
}

.ant-pagination-item-active {
  background: #3e79f7 !important;
  border-color: #3e79f7 !important;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 50% !important;
}

.ant-tabs-tab {
  padding: 10px 20px !important;
  margin-right: 18px !important;
  font-size: 16px !important;
}

/*   
  thead[class*="ant-table-thead"] th {
    background-color: var(--brandColor) !important;
    color: rgb(250, 250, 250) !important;
    font-size: 1.1rem;
    font-weight: 400 !important;
  }
  
  .ant-table table {
    border-spacing: 0 0.4rem !important;
  }
  
  .ant-table table > tr {
    padding: 0 !important;
    font-family: "Poppins", sans-serif !important;
    height: 52px !important;
  }
  
  .ant-table-thead > tr > th {
    padding: 0.4rem 0.6rem !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .ant-table-tbody > tr > td {
    font-family: "Poppins", sans-serif !important;
  
    padding: 0.2rem 0.6rem !important;
    background-color: var(--acentColor) !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
  
  .ant-table-row-expand-icon {
    background: #f1f6f9 !important;
    border: 1px solid #f1f6f9 !important;
    transform: scale(1.34117647) !important;
    transform-origin: center !important;
  }
  .ant-table-row-expand-icon:focus {
    outline: none !important;
  }
  .ant-table-row-expand-icon::before,
  .ant-table-row-expand-icon::after {
    background-color: var(--brandColor) !important;
  }
  
  .ant-table-row {
    height: 40px !important;
  }
  .ant-table-row p,
  .ant-table-row h5,
  .ant-table-row svg {
    margin: 0 !important;
  } */

.ant-space-item {
  height: 30.3px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-checkbox-inner::after {
  top: 40% !important;
  left: 13% !important;
  width: 6.71428571px !important;
  height: 9.14285714px !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50% !important;
  left: 50% !important;
  width: 8px !important;
  height: 8px !important;
}

/* .ant-table-thead > tr > th {
    text-transform: capitalize !important;
    text-align: center !important;
  }
  
  .ant-table-tbody > tr > td {
    text-align: center !important;
    font-size: 0.8rem;
  }
   */

.ant-select-arrow {
  /* color: var(--brandColor) !important; */
  color: rgb(172, 171, 171) !important;
}

.ant-table-filter-trigger {
  color: #ffffff !important;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  background: #f4f9ff !important;
  margin-bottom: 7px;
  font-family: "Poppins", sans-serif !important;
}

.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  background: #f1f6f9 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.8rem !important;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transition: 1s;
  font-size: 0.8rem !important;
  color: var(--brandColor) !important;
}

::-webkit-scrollbar {
  width: 5px !important;
}

/* Track  */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.513) !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  float: left;
  padding-left: 5px;
}

.ant-picker-cell-inner.ant-picker-calendar-date {
  height: 90px !important;
  border: 1px solid#f0f0f0 !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border: 1px solid #3498db !important;
}
